* {
  box-sizing: border-box;
}

body {
  margin: 10;
}

.container {
  margin: 1rem;
  text-align: center;
}


.nav {
  background-color: #ffffff;
  color: rgb(5, 5, 5);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
}

.site-title {
  font-size: 30px;
}

.nav img {
  height: 75px;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  background-color: #fffeff;
}

.nav li:hover {
  background-color: #fdfbfb;
}

.footerView {
  margin-top: 2rem;
  height: 80px;
  background-color: white;
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.footerView h1 {
  margin-top: 3rem;
  font-size: 15px;
  text-align: center;
}