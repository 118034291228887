@import 'https://fonts.googleapis.com/css?family=Kanit|Prompt';
@media all and (max-width: 890px) {
    .homeMain ul.ulBlog {
        -webkit-flex-flow: column;
        flex-flow: column;
        padding: 0;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.homeMain ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
}

.homeMain ul.ulBlog {
    list-style: none;
    margin: 0;
    /* background: deepskyblue; */
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    align-self: center;

}

.homeMain h1.h1Title {
    text-align: left;
    height: 300px;
    padding-top: 3%;
    margin-left: 5rem;
    font-size: 5vmin;
    font-family: 'Kanit', sans-serif;
    
}

.blogmain {
    width: 310px;
    margin: 0.2rem;
    height: 460px;
    box-shadow: 1px 1px 10px black;
    /* border-radius: 50px; */
    text-align: center;
}

/* .ul
{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
} */

.img {
    height: 250px;
    background-color: brown;
    /* border-top-left-radius: 50px;
    border-top-right-radius: 50px; */

}

.content {
    margin-top: 1rem;
    text-align: center;
}